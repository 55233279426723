import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";
import { config } from './config';



export function initializeFirebase() {

    const apps = getApps();
    const firebaseApp = !apps.length ? initializeApp(config.firebase) : apps[0];
    const auth = getAuth(firebaseApp);
    const db = getFirestore(firebaseApp);
    const rtdb = getDatabase(firebaseApp);
    return { firebaseApp, auth, db, rtdb};
}

export function initializeGenericBot() {
    const botFirebaseApp = initializeApp(config.genericBot, "botFirebase");
    const db = getDatabase(botFirebaseApp);
    return { botFirebaseApp, db };
}