import Api from '@/services/api/Api';

export default {
    getEvents(uid: string) {
        return Api().get(`${uid}/events`);
    },
    getPastEvents(email: string) {
        return Api(process.env.VUE_APP_SPEXT_API_BASE_URL).get(`/publish/episodes?email=${email}`);
    },
    getRecordings   (uid: string, email: string) {
        return Api().get(`${uid}/recordings?email=${email}`);
    }
};