<script>
import { defineComponent } from 'vue';
import consoleLog from '@/utils/console-log';
import SideNavigationBar from "@/components/SideNavigationBar.vue";
import EventsList from "@/components/EventsList.vue";
import SettingsBar from "@/components/SettingsBar.vue";
export default defineComponent({
  name: 'DashboardView',
  components: {
    SideNavigationBar,
    EventsList,
    SettingsBar,
  },
});
</script>

<template>
  <div class="flex flex-1 h-screen overflow-hidden bg-primary-100 min-w-max">
    <div class="flex flex-col items-center nav">
      <SideNavigationBar />
    </div>
    <div class="relative grid w-full grid-cols-10 gap-4 pl-3 ml-16">
      <section class="relative h-screen col-span-7 bg-white shadow-lg">
        <EventsList />
      </section>
      <section class="relative flex flex-col h-screen col-span-3 overflow-hidden px-1 bg-white border-l border-primary-300">
        <SettingsBar />
      </section>
    </div>
  </div>
</template>
