import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import {auth} from "./modules/authStore";
import {userSettings} from "./modules/settingsStore";

// Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    userSettings,
  },

  plugins: [createPersistedState()],
});