import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DashboardView from "@/views/DashboardView.vue";
import AuthView from "@/views/AuthView.vue";
import AddAccountView from "@/views/AddAccountView.vue";
import ShutdownNoticeView from "@/views/ShutdownNoticeView.vue";
import store from "@/store";
import AccountApi from "@/services/api/Account";
import consoleLog from "@/utils/console-log";
import { initializeFirebase } from "@/firebase";
import { signInWithCustomToken } from "firebase/auth";
const { auth } = initializeFirebase();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresAuth: true, hasAccount: true },
  },
  {
    path: "/shutdown-notice",
    name: "shutdown-notice",
    component: ShutdownNoticeView,
    meta: { requiresAuth: true},
  },
  {
    path: "/setup",
    name: "setup",
    component: AddAccountView,
    meta: { requiresAuth: true },
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthView,
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state.auth.isAuthenticated;

  if (!isAuthenticated && to.query.token) {
    const token = to.query.token as string;
    try {
      const { user } = await signInWithCustomToken(auth, token);
      await store.dispatch("auth/setUser", user);
    } catch (e) {
      consoleLog(`Error signing in with custom token: ${e}`);
    }
  }

  const uid = store.state.auth.uid;
  console.log("before Each", to.name);
  if (to.name === "shutdown-notice") {
    next();
  }
  if (to.matched.some((record) => record.meta.hasAccount)) {
    if (isAuthenticated) {
      try {
        const response = await AccountApi.getAccounts(uid);
        if (response.data.status === "success") {
          if (response.data.data.accounts.length > 0) {
            next();
          } else {
            console.log("redirec tto setup");
            next({ name: "setup" });
          }
        } else {
          console.log("redirec tto setup");
          next({ name: "setup" });
        }
      } catch (error) {
        next({ name: "setup" });
        consoleLog(`Router Fetch Account Error: ${error}`);
      }
    } else {
      next({ name: "auth" });
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: "auth" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
