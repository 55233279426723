import Api from '@/services/api/Api';

export default {
    updateSettings(uid: string, body: any) {
        return Api().post(`${uid}/settings`, body);
    },

    getSettings(uid: string) {
        return Api().get(`${uid}/settings`);
    },

    updateEventSettings(uid: string, eid: string, body: any) {
        return Api().post(`${uid}/${eid}/settings`, body);
    }
};