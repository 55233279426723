export const config = {
    firebase: {
        apiKey: process.env.VUE_APP_SPEXT_FIREBASE_APIKEY,
        authDomain: process.env.VUE_APP_SPEXT_FIREBASE_AUTHDOMAIN,
        databaseURL: process.env.VUE_APP_SPEXT_FIREBASE_DATABASEURL,
        projectId: process.env.VUE_APP_SPEXT_FIREBASE_PROJECTID,
        storageBucket: process.env.VUE_APP_SPEXT_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.VUE_APP_SPEXT_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.VUE_APP_SPEXT_FIREBASE_APPID,
        measurementId: process.env.VUE_APP_SPEXT_FIREBASE_MEASUREMENTID
    },
    genericBot:{
        apiKey: process.env.VUE_APP_GENERIC_BOT_FIREBASE_APIKEY,
        authDomain: process.env.VUE_APP_GENERIC_BOT_FIREBASE_AUTHDOMAIN,
        databaseURL: process.env.VUE_APP_GENERIC_BOT_FIREBASE_DATABASEURL,
        projectId: process.env.VUE_APP_GENERIC_BOT_FIREBASE_PROJECTID,
        storageBucket: process.env.VUE_APP_GENERIC_BOT_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.VUE_APP_GENERIC_BOT_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.VUE_APP_GENERIC_BOT_FIREBASE_APPID,
        measurementId: process.env.VUE_APP_GENERIC_BOT_FIREBASE_MEASUREMENTID
    },
};