<script lang="js">
import SettingsApi from '@/services/api/Settings'
import consoleLog from '@/utils/console-log'
import RecordInstantly from "@/components/RecordInstantly.vue";
import AccountsList from "@/components/AccountsList.vue";
import RadioInput from "@/components/input/RadioInput.vue";

export default {
	name: "SettingsBar",
	components: {
    RecordInstantly,
    AccountsList,
    RadioInput,
  },
	data() {
		return {
      record:'',
			share:'',
      updateSettingsLoading: false,
		}
	},
	methods: {
		changeRecord(val) {
			this.record = val;
      this.updateSettings()
		},
		changeShare(val) {
			this.share = val;
      this.updateSettings()
		},
    async updateSettings(){
      this.updateSettingsLoading = true
      const uid = this.$store.state.auth.uid
      try{
        const body = {
          settings:{
            record: this.record,
            share: this.share,
          }
        }
        const response = await SettingsApi.updateSettings(uid, body)
        if(response.data.status === 'success'){
          this.$store.dispatch('userSettings/setSettings', body.settings)
          this.updateSettingsLoading = false
        }
      }catch (error) {
        consoleLog(`Update Settings Error: ${error}`)
        this.updateSettingsLoading = false
      }
    },
	},
  mounted() {
    this.$store.dispatch('userSettings/getSettings')
    this.record = this.$store.state.userSettings.settings.record;
    this.share = this.$store.state.userSettings.settings.share;
  },
}
</script>

<template>
	<div class=" h-screen bg-white min-w-max overflow-y-scroll">
		<div class="px-6 py-8 flex flex-col gap-9 text-primary">
      <RecordInstantly/>
      <div class="bg-primary-300 h-px w-full"/>
			<div class="flex flex-col font-h text-left">
				<div class="font-semibold text-2xl mb-8 flex items-center">
					<h1>Calendar settings</h1>
          <img v-if="updateSettingsLoading" src="@/assets/icons/loader.png" class="w-5 h-5 ml-4 rounded-md overflow-hidden animate-spin-slow loader">
				</div>
				<div class="flex mb-5 flex-col gap-y-4 text-xs font-ui">
					<div class="font-semibold text-lg font-h">
						<h2>Which meetings should Spext join?</h2>
					</div>
<!--					<div @click="changeRecord('all')" >-->
<!--						<RadioInput :name="'joinSelection'" :checked="record == 'all'" :label="'Join all the meetings on your calendar'"></RadioInput>-->
<!--					</div>-->
					<div @click="changeRecord('mine')" >
						<RadioInput :name="'joinSelection'" :checked="record == 'mine'" :label="'Join only meetings I own'"></RadioInput>
					</div>
					<div @click="changeRecord('none')" >
						<RadioInput :name="'joinSelection'" :checked="record == 'none'" :label="'Don’t join any meetings automatically'"></RadioInput>
					</div>
				</div>
<!--				<div class="flex flex-col gap-y-4 text-xs font-semibold font-h">-->
<!--					<div class="font-semibold text-base font-ui">-->
<!--						<h2>Who should get the recording?</h2>-->
<!--					</div>-->
<!--					<div @click="changeShare('me')" >-->
<!--						<RadioInput :name="'meetings'" :checked="share == 'me'" :label="'Only Me'"></RadioInput>-->
<!--					</div>-->
<!--					<div @click="changeShare('all')" >-->
<!--						<RadioInput :name="'meetings'" :checked="share == 'all'" :label="'All Participants in the invite'"></RadioInput>-->
<!--					</div>-->
<!--				</div>-->
			</div>
			<div class="bg-primary-300 h-px w-full"/>
      <AccountsList />
		</div>
	</div>
</template>