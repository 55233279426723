<script lang="js">
import EventsApi from '@/services/api/Events';
import SettingsApi from '@/services/api/Settings'
import SwitchInput from "@/components/input/SwitchInput.vue";
import moment from 'moment';
import consoleLog from "@/utils/console-log";

export default {
	name: "EventsList",
	components: {
    SwitchInput,
  },
	data() {
		return {
      events: [],
      noEvents: false,
      pastEvents: [],
      noPastEvents: false,
			selected: 1,
      timer: '',
      recordingsTimer: '',
      hoverKey: '',
      recordingHoverKey: '',
      updatingEventSettings: false,
			meeting1: 'meet',
			meeting2: 'other',
		}
	},
  created() {
    this.fetchEvents();
    this.fetchPastEvents();
    this.timer = setInterval(this.fetchEvents, 10000);
    this.recordingsTimer = setInterval(this.fetchPastEvents, 300000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
    clearInterval(this.recordingsTimer);
  },
  methods: {
		changeSelected(id) {
			this.selected = id
		},
    async fetchEvents(){
      const uid = this.$store.state.auth.uid
      try{
        const response = await EventsApi.getEvents(uid)
        const unsortedEvents = response.data.data.events
        const shortedEvents = unsortedEvents.sort((a, b) => new Date(a.start_time) > new Date(b.start_time) ? 1 : -1)
        const emptyEvents = []
        shortedEvents.forEach((event)=>{
          if(this.filterByEndTime(event)){
            //this.fetchEventStatus(event)
            emptyEvents.push(this.botAllowed(event))
          }
        });
        this.events = emptyEvents
        if(this.events.length == 0) {
          this.noEvents = true
        }else{
          this.noEvents = false
        }
      } catch (error) {
        consoleLog(`Fetch meetings Error: ${error}`)
      }
    },
    async fetchPastEvents(){
      const email = this.$store.state.auth.email
       const uid = this.$store.state.auth.uid
      try{
        const response = await EventsApi.getRecordings(uid, email)
        this.pastEvents = response.data.recordings.reverse()
        if (this.pastEvents.length == 0){
          this.noPastEvents = true
        }else{
          this.noPastEvents = false
        }
      } catch (error) {
        consoleLog(`Fetch recordings Error: ${error}`)
      }
    },
    async updateEventSettings(value, event){
      const uid = this.$store.state.auth.uid
      const eid = event.sk.replace("e#", "");
      if(this.updatingEventSettings){
        return
      }
      if(event.organizer){
        this.updatingEventSettings = true
        clearInterval(this.timer);
        event.hasSetting = true
        event.bot_allowed = value
        try{
            const body = {
              settings:{
              record: value? "True": "False",
              }
            }
            const response = await SettingsApi.updateEventSettings(uid, eid, body)
            if(response.data.status == 'success'){
              event.bot_allowed = value
              //this.fetchEvents()
            }else{
              event.bot_allowed = !value
            }
        }catch (error) {
          event.bot_allowed = !value
          consoleLog(`Update event settings Error: ${error}`)
        }
        this.timer = setInterval(this.fetchEvents, 10000);
        this.updatingEventSettings = false
      }
    },
    formatTitle(pastEvent){
      const meetingTitle = pastEvent.title.replace("Recording in Spext at"," ")
      return this.truncate(meetingTitle)
    },
    editRecording(pastEvent){
      if(pastEvent.edit_url == null){
        return
      }
      const spextDomain = process.env.VUE_APP_SPEXT_DOMAIN
      const editUrl = `${spextDomain}${pastEvent.edit_url}`
      window.open(editUrl, '_blank')
    },
    viewRecording(pastEvent){
      const publishDomain = process.env.VUE_APP_PUBLISH_DOMAIN
      const videoUrl = `${publishDomain}/video/${pastEvent.publish}`
      window.open(videoUrl, '_blank')
    },
    recordNow(event){
      const botUrl = process.env.VUE_APP_GENERIC_BOT
      const isCalRedirect = true
      const meetingLink = event.meeting_info.meet_link
      const emailId = this.$store.state.auth.email
      const userName = this.$store.state.auth.name
      const meetingTitle = event.meeting_info.name
      const meetingPasscode = event.meeting_info.passcode
      const recordUrl = `${botUrl}/?isCalRedirect=${isCalRedirect}&emailId=${emailId}&meetingLink=${meetingLink}&userName=${userName}&meetingTitle=${meetingTitle}&meetingPasscode=${meetingPasscode}`
      window.open(recordUrl, '_blank')
    },
    formatPastEventTime(pastEvent){
      const pastEventTime = pastEvent.pub_date
      return moment(pastEventTime).format('MMM DD, YYYY')
    },
    formatMeetLink(event){
      if(event.meeting_info.type === "GM"){
        return event.meeting_info.meet_link
      }else{
        const meetLink = event.meeting_info.meet_link
        return meetLink.split("?")[0];
      }
    },
    formatRecordingTime(pastEvent){
      const recordingTime = pastEvent.length
      const time = new Date(recordingTime * 1000).toISOString().substr(11, 8);
      const [hours, minutes, seconds] = time.split(":").map(Number);
      if (hours > 0) {
        return `${hours} hr ${minutes} min`;
      } else if (minutes > 0) {
        return `${minutes} min`;
      } else {
        return `${seconds} sec`;
      }
    },
    filterByEndTime(event){
      return moment(event.end_time).isAfter(moment())
    },
    filterByStartTime(event){
      return moment(event.start_time).isSameOrBefore(moment())
    },
    findOrganizer(event){
      if(event.organizer){
        return event.meeting_info.email
      }else{
        const attendees = event.meeting_info.attendees
        const organizer = attendees.find(attendee => attendee.organizer)
        if(typeof organizer !== 'undefined') {
          return organizer.email
        }else{
          return "unknown"
        }
      }
    },
    formatStartTime(event){
      const startDateTime = new Date(event.start_time);
      const endDateTime = new Date(event.end_time);
      const dateYear = startDateTime.toLocaleString('en-us',{month: 'short', day: 'numeric', year: 'numeric'})
      const startTime = startDateTime.toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', hour12: true});
      const endTime = endDateTime.toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', hour12: true});
      return `${dateYear} | ${startTime} - ${endTime}`
    },
    truncate(text) {
      const length = 45;
      const suffix = '...';
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    botAllowed(event){
      const record = this.$store.state.userSettings.settings.record;
      if(typeof (event.join_meeting) === 'undefined'){
        if(record === 'none') {
          return ({...event, bot_allowed: false, hasSetting: false})
        }else if(record === 'mine'){
          if(event.organizer) {
            return ({...event, bot_allowed: true, hasSetting: false})
          }else{
            return ({...event, bot_allowed: false, hasSetting: false})
          }
        }
      }else {
        if(event.join_meeting === 'True'){
          return ({...event, bot_allowed: true, hasSetting: true})
        }
        else if(event.join_meeting === 'False'){
          return ({...event, bot_allowed: false, hasSetting: true})
        }
      }
    },
	}, // end methods
  watch: {
    '$store.state.userSettings.settings.record': function (val) {
      const record = this.$store.state.userSettings.settings.record;
      this.events.forEach((event)=>{
        if(!event.hasSetting){
          if(record === 'none') {
            event.bot_allowed = false
          }else if(record === 'mine'){
            if(event.organizer) {
              event.bot_allowed = true
            }else{
              event.bot_allowed = false
            }
          }
      }});
    }
  },
};
</script>
<template>
	<div class="min-w-max">
		<div class="bg-white shadow-md h-screen w-full p-10  text-primary">
			<div class="flex flex-col gap-10">
				<div class="flex flex-row justify-between align-center">
					<div class="flex gap-2 items-center font-h font-semibold">
						<img :src="require('@/assets/icons/calendar.svg')" alt="">
						<div class="text-4xl align-start">
              <h1>Your Calendar</h1>
						</div>
					</div>
				</div>

				<div class="flex flex-col gap-y-0">
					<div class="flex gap-4 w-full border-b border-[#8D9EBF] border-opacity-20 font-ui text-sm ">
						<transition enter-active-class="transition duration-400 ease-in-out" leave-active-class="transition duration-400 ease-in-out" name="ease-in-out-modal">
							<div @click="changeSelected(1)" class="h-8 w-32 cursor-pointer flex justify-center " :class="selected === 1 ? 'items-center  border-b-4 border-red text-primary  transition duration-400  delay-400 ' : 'items-end text-primary-600 pb-1 transition duration-400 delay-200 '">
								Upcoming
							</div>
						</transition>
						<transition enter-active-class="transition duration-400 ease-in-out" leave-active-class="transition duration-400 ease-in-out" name="ease-in-out-modal">
							<div @click="changeSelected(2); fetchPastEvents()" class="h-8 w-32 cursor-pointer flex justify-center pb-1 "  :class="selected === 2 ? 'items-center  border-b-4 border-red text-primary  transition duration-400  delay-400 ' : 'items-end text-primary-600 pb-1 transition duration-400 delay-200 '">
								Past Recordings
							</div>
						</transition>
					</div>

          <div v-show="selected == 1" class="flex flex-col mt-2 text-ui px-1 max-h-[78vh] overflow-auto">
            <div v-if="noEvents">
                <div class="flex flex-col justify-center items-center h-[61vh]">
                  <div class="mb-6">
                    <img :src="require('@/assets/icons/spext-tension.svg')" class="" alt="">
                 </div>
                  <div class="">
                    <h1 class="text-center text-primary-500 mb-3">Looks like you have an empty calendar!</h1>
                    <p class="text-center">All your schedule meetings will show up here automatically. </p>
                  </div>
              </div>
            </div>

            <div v-for="event in events" :key="event.sk">
              <div v-show="filterByStartTime(event)" @mouseover="hoverKey = event.sk" @mouseleave="hoverKey = ''"  class=" w-full h-full ">
								<div  class="m-1 pl-2.5 py-4 pr-6 flex justify-between items-center h-[80px] drop-shadow-xl border border-green rounded"  :class="hoverKey==event.sk? 'bg-primary-100': 'bg-white'" >
									<div class="flex gap-2 h-10">
										<div class="w-2 h-2 rounded-full  opacity-20 mt-1.5 " :class="{'bg-cobalt' : meeting1 === 'meet'}"></div>
										<div class="flex flex-col gap-2 text-left">
											<div class="text-sm flex  text-primary">
                        <a :href="event.meeting_info.meet_link" target="_blank" class="font-medium" :class="hoverKey == event.sk? 'underline': ''" >{{truncate(event.event_title)}}</a>
                        <div class="flex">
                          <img :src="require('@/assets/icons/in-progress.svg')" class="ml-2" alt="">
                          <h3 class="ml-1.5 w-64 text-green">Live</h3>
                        </div>
                      </div>
											<div class="text-xs flex  text-primary-800 ">
                        <div class="flex">
                          <img :src="require('@/assets/icons/url-link.svg')" class="mb-0.5" alt="">
                          <a :href="event.meeting_info.meet_link" target="_blank" class="ml-2 w-64 underline text-xs text-blue-600 overflow-hidden">{{formatMeetLink(event)}}</a>
                        </div>
                        <div class="flex">
                          <img :src="require('@/assets/icons/user.svg')"  alt="">
                          <h3 class="ml-1.5 w-30"><span class="font-semibold">Organiser: </span><span>{{findOrganizer(event)}}</span></h3>
                        </div>
                      </div>
										</div>
									</div>
                  <VTooltip
                      placement="left-start"
                      :skidding="-8"
                  >
                    <div v-show="event.organizer" :class="event.organizer? 'opacity-100': 'opacity-40'">
									<SwitchInput :value="event.bot_allowed" @input="(value) => updateEventSettings(value, event)"  />
                    </div>
                    <div v-show="!event.organizer">
                      <button @click="recordNow(event)" class="w-32 border bg-primary font-ui text-2xs uppercase font-semibold rounded-lg flex h-9 flex justify-center items-center">
                      <h1 class="text-xs text-white">Record Now</h1>
                      <img :src="require('@/assets/icons/arrow-white.svg')" alt="" class="pl-2">
                      </button>
                    </div>
                    <template #popper>
                      <div v-show="!event.organizer" class="p-2 rounded-md border border-primary-300 shadow-md bg-white">
                        <p class="text-xs">Click to start recording</p>
                      </div>
                      <div v-show="event.organizer" class="p-2 rounded-md border border-primary-300 shadow-md bg-white">
                        <p v-show="!event.bot_allowed" class="text-xs">Click to start recording</p>
                        <p v-show="event.bot_allowed" class="text-xs">Bot will be joining shortly</p>
                      </div>
                    </template>
                  </VTooltip>
								</div>
							</div>

							<div v-show="!filterByStartTime(event)" @mouseover="hoverKey = event.sk" @mouseleave="hoverKey = ''"  class=" w-full h-full">
								<div  class="m-1 pl-2.5 py-3.5 pr-6 flex justify-between items-center h-[70px]"  :class="hoverKey==event.sk ? 'bg-primary-100': 'bg-white'" >
									<div class="flex gap-2 h-10">
										<div class="w-2 h-2 rounded-full  opacity-20 mt-1.5 " :class="{'bg-cobalt' : meeting1 === 'meet'}"></div>
										<div class="flex flex-col gap-2 text-left">
											<div class="text-sm flex  text-primary">
                        <a :href="event.meeting_info.meet_link" target="_blank" class="font-medium" :class="hoverKey == event.sk? 'underline': ''" >{{truncate(event.event_title)}}</a>
                        <img :src="require('@/assets/icons/url-link.svg')" class="ml-2" :class="hoverKey==event.sk ? 'opacity-100': 'opacity-30'" alt="">
                        <div class="flex">
                          <a :href="event.meeting_info.meet_link" target="_blank" v-show="hoverKey == event.sk" class="ml-2 underline text-xs text-blue-600 overflow-hidden">{{formatMeetLink(event)}}</a>
                        </div>
                      </div>
											<div class="text-xs flex  text-primary-800 ">
                        <div class="flex">
                          <img :src="require('@/assets/icons/small-calendar.svg')" class="mb-0.5" alt="">
                          <h3 class="ml-1.5 w-64">{{formatStartTime(event)}}</h3>
                        </div>
                        <div class="flex">
                          <img :src="require('@/assets/icons/user.svg')"  alt="">
                          <h3 class="ml-1.5 w-30"><span class="font-semibold">Organiser: </span><span>{{findOrganizer(event)}}</span></h3>
                        </div>
                      </div>
										</div>
									</div>
                  <VTooltip
                      placement="left-start"
                      :skidding="-11"
                      :trigger="['hover', 'focus']"
                  >
                    <div :class="event.organizer ? 'opacity-100': 'opacity-50'">
                      <div :class="event.organizer ? '': 'hover:opacity-40'">
                        <SwitchInput :value="event.bot_allowed" @input="(value) => updateEventSettings(value, event)"  />
                        </div>
                    </div>
                    <template #popper>
                      <div v-show="!event.organizer" class="p-2 px-3 rounded-md border border-red-700 shadow-md bg-red-100">
                        <p class="text-xs">Whoops! Can't join meetings you don't own 😅</p>
                        <p class="text-xs text-red text-right">Try again when the meeting is live</p>
                      </div>
                      <div v-show="event.organizer" class="p-2 rounded-md border border-primary-300 shadow-md bg-white">
                        <p v-show="!event.bot_allowed" class="text-xs">Click to invite Spext Bot</p>
                        <p v-show="event.bot_allowed" class="text-xs">Click to remove Spext Bot</p>
                      </div>
                    </template>
                  </VTooltip>
								</div>
                <div class="border-b "></div>
							</div>
            </div>

          </div>
						<div v-show="selected == 2" class="flex flex-col mt-2  text-ui px-1 max-h-[78vh] overflow-auto">
               <div v-if="noPastEvents">
                 <div class="flex flex-col justify-center items-center h-[59vh]">
                   <div class="mb-6">
                    <img :src="require('@/assets/icons/spext-tension.svg')" class="" alt="">
                    </div>
                  <div class="">
                    <h1 class="text-center text-primary-500 mb-5">Whoops! Spext hasn't recorded any meetings yet.</h1>
                  </div>
                 </div>
              </div>

              <div v-for="pastEvent in pastEvents" :key="pastEvent.publish">

							<div v-if="pastEvent.length" @mouseover="recordingHoverKey = pastEvent.publish" @mouseleave="recordingHoverKey = ''"  class="w-full h-full">
								<div class="m-1 pl-2.5 py-3.5 pr-6 flex justify-between items-center h-[70px]" :class="recordingHoverKey==pastEvent.publish? 'bg-primary-100':'bg-white'" >
									<div class="flex gap-2 h-10">
										<div class="w-2 h-2 rounded-full  opacity-20 mt-1.5 " :class="{'bg-cobalt' : meeting1 === 'meet'}"></div>
										<div class="flex flex-col gap-2 text-left">
											<div class="text-sm font-medium text-primary">{{formatTitle(pastEvent)}}</div>
											<div class="text-xs flex text-primary-800 ">
                        <img :src="require('@/assets/icons/small-calendar.svg')" class="mb-0.5" alt="">
                        <h3 class="ml-1.5 w-24">{{formatPastEventTime(pastEvent)}}</h3>
                        <img :src="require('@/assets/icons/clock.svg')" class="mb-0.5" alt="">
                        <h3 class="ml-1.5 w-24">{{formatRecordingTime(pastEvent)}}</h3>
                      </div>
										</div>
									</div>
									<div class="flex gap-2">
										<button @click="editRecording(pastEvent)" :class="pastEvent.edit_url === null? 'opacity-40 cursor-not-allowed':'' " class="w-14 border hover:bg-primary-100 hover:border-primary-400 border-primary-400 font-ui text-2xs uppercase text-primary font-semibold rounded-lg flex h-8 flex justify-center items-center">
                      <h1 class="text-xs">Edit</h1>
                    </button>
										<button @click="viewRecording(pastEvent)" :class="recordingHoverKey==pastEvent.publish?'bg-primary':''" class="w-36 border hover:bg-primary-900 hover:border-primary-400 border-primary-400 font-ui text-2xs uppercase text-primary font-semibold rounded-lg flex h-8 flex justify-center items-center">
                      <h1 class="text-xs" :class="recordingHoverKey==pastEvent.publish? 'text-white':''">View Recording</h1>
                      <img v-if="recordingHoverKey!=pastEvent.publish" :src="require('@/assets/icons/arrow-top-right-icon.svg')" alt="" class="pl-2">
                      <img v-if="recordingHoverKey==pastEvent.publish" :src="require('@/assets/icons/arrow-white.svg')" alt="" class="pl-2">
                    </button>
									</div>
								</div>
                <div class="border-b"></div>
							</div>
                </div>
						</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.v-popper--theme-tooltip .v-popper__inner {
  color: black;
  background: none;
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
  visibility: hidden;
  border-color: white;
  background: white;
}
</style>