<script lang="js">
import FirebaseAuth from "@/components/FirebaseAuth.vue";
import SideNavigationBar from "@/components/SideNavigationBar.vue";
export default{
    name: 'AuthView',
    components: {
    FirebaseAuth,
    SideNavigationBar
},
    computed: {
        CalendarDomain() {
            return location.origin;
        },
    },
};
</script>
<template>
  <div class="flex flex-col items-center w-screen h-screen pl-16">
    <SideNavigationBar />
    <div class="relative flex-grow w-full max-w-full ml-6 shadow-lg">
      <div class="w-full h-full pt-10">
        <FirebaseAuth />
      </div>
      <img
        :src="require('@/assets/icons/auth-page.svg')"
        class="absolute bottom-0 left-1/2 transform -translate-x-1/2"
        alt=""
      />
    </div>
  </div>
</template>