
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: Boolean,
			default: false
		},
		hightlight: {
			type: Boolean,
			default: true
		}
	},
	setup(props) {
		props.value,
		props.hightlight
	},
	computed: {
		backgroundStyles: function() : any{
			return {
				'bg-green': this.value,
				'bg-primary-200': !this.value && !this.hightlight,
				'bg-red': !this.value && this.hightlight
			}
		},
		indicatorStyles: function() : any {
			return {
				transform: this.value ? 'translateX(28px)' : 'translateX(0)',
				border: this.value ? '1px solid #818181' : '1px solid #D7DBE7'
			}
		}
	},
	methods: {
		toggle() : void {
			this.$emit('input', !this.value)
		}
	}
})
