<script lang="js">
	import StepIcon from '@/components/icons/StepIcon.vue'
  import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

	export default {
		name: 'StepDesign',
		props: {
			isEnabled: {
				type: Boolean,
				required: false,
				default: true
			},
			open: {
				type: Boolean,
				required: false,
				default: true
			},
			stepHeadClass: {
				type: String,
				required: false,
				default: ''
			},
			stepBodyClass: {
				type: String,
				required: false,
				default: ''
			},
			showConnector: {
				type: Boolean,
				required: false,
				default: true
			}
		},
		components: {
			StepIcon,
			CollapseTransition
		},

  }
</script>

<template>
	<div class="flex relative">
		<div
			class="flex flex-col mr-2.5 pt-3 transition w-4 duration-300 items-center"
			@click="$emit('onClick')"
			:class="[isEnabled ? 'cursor-pointer' : 'cursor-not-allowed']"
		>
			<StepIcon
				class="transition ease-in-out duration-300"
				:class="[open ? 'h-4 text-primary w-4' : 'h-3 w-3 inactiveStepIcon text-primary-500']"
			/>
		</div>

		<div class="w-full pb-6 relative">
			<div v-if="showConnector" class="bg-primary-400 top-8 -bottom-1.5 -left-4.5 w-0.25 absolute"/>
			<div v-if="showConnector" class="border border-white border-dashed top-8 -bottom-1.5 -left-4.5 absolute"/>

			<div
				class="font-semibold font-h"
				:class="[
            open ? 'text-primary px-2 pt-1.5 pb-3.5 text-1.5xl' : 'p-2 text-primary-500 text-base',
            isEnabled ? 'cursor-pointer' : 'cursor-not-allowed',
            stepHeadClass
          ]"
			>
				<slot/>
			</div>

			<collapse-transition :duration="500" :class="stepBodyClass">
				<div v-show="open">
					<slot name="stepBody"/>
				</div>
			</collapse-transition>

		</div>
	</div>
</template>

<style lang="scss" scoped>
.inactiveStepIcon {
	transform: rotate(-90deg);
}
</style>