
import { defineComponent } from 'vue';
import type { PropType} from "vue";
import consoleLog from '@/utils/console-log';
import SetupAccount from "@/components/SetupAccount.vue";
import SideNavigationBar from "@/components/SideNavigationBar.vue";

export default defineComponent({
  name: 'AddAccountView',
  components: {
    SetupAccount,
    SideNavigationBar,
  },
});
