<script>
  export default {
    name: 'StepIcon',
    props: {
      classname: {
        type: String,
        default: ''
      }
    }
  }
</script>

<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      :class="classname"
  >
    <path d="M16.2125 4.60454C17.3734 2.9477 16.1882 0.669922 14.1651 0.669922L2.50443 0.669921C0.481329 0.669921 -0.70393 2.94772 0.457039 4.60456L6.30109 12.9447C7.28981 14.3557 9.37988 14.3557 10.3686 12.9447L16.2125 4.60454Z" />
  </svg>
</template>

<style lang="css" scoped>
  svg {
    fill: currentColor;
  }
</style>
