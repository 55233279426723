<script lang="js">

export default {
  name: 'RecordInstantly',
  data(){
    return {
      inputMeetingUrl: '',
      inputPasscode: '',
      showPasscodeInput: false,
      invalidPasscode: false,
      invalidPasscodeMessage: '',
      validMeetingUrl: false,
      validMeetingUrlMessage: '',
      invalidMeetingUrl: false,
      invalidMeetingUrlMessage: '',
    }
  },
  methods: {
    startRecording(){
      if (this.inputMeetingUrl.length === 0) {
        this.invalidMeetingUrl = true
        this.invalidMeetingUrlMessage = 'Please enter a meeting link!'
        return
      }
      if (this.invalidMeetingUrl) {
        return
      }
      // if(this.showPasscodeInput && this.inputPasscode.length === 0){
      //   this.invalidPasscode = true
      //   this.invalidPasscodeMessage = 'Please enter a passcode!'
      //   return
      // }
      const botUrl = process.env.VUE_APP_GENERIC_BOT
      const isCalRedirect = true
      const meetingLink = this.inputMeetingUrl
      const meetingPasscode = this.inputPasscode
      const emailId = this.$store.state.auth.email
      const userName = this.$store.state.auth.name
      const recordUrl = `${botUrl}/?isCalRedirect=${isCalRedirect}&emailId=${emailId}&meetingLink=${meetingLink}&userName=${userName}&meetingPasscode=${meetingPasscode}`
      window.open(recordUrl, '_blank')
      this.inputMeetingUrl = ''
      this.inputPasscode = ''
      this.showPasscodeInput = false
      this.validMeetingUrl = false
      this.validMeetingUrlMessage = ''
    },
    isValidUrl(){
      this.validMeetingUrl = false
      this.showPasscodeInput = false
      if (this.inputMeetingUrl.length === 0) {
        this.invalidMeetingUrl = false
        this.invalidMeetingUrlMessage = ''
        return
      }
      const urlPattern = new RegExp(
          '^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

      if(this.inputMeetingUrl.includes('zoom.us')){
        if (!this.inputMeetingUrl.includes('?pwd=')) {
          this.showPasscodeInput = true
        }
      }
      if (!urlPattern.test(this.inputMeetingUrl)) {
        this.invalidMeetingUrl = true
        this.invalidMeetingUrlMessage = 'Meeting URL not found!'
        return
      }
      if (!this.inputMeetingUrl.includes('zoom.us') && !this.inputMeetingUrl.includes('meet.google.com')) {
        this.invalidMeetingUrl = true
        this.invalidMeetingUrlMessage = 'Please enter a valid meeting link!'
        return
      }

      this.invalidMeetingUrl = false
      this.validMeetingUrl = true
      this.validMeetingUrlMessage = 'Meeting link verified!'
    },
    removeInvalidPasscode(){
      this.invalidPasscode = false
      this.invalidPasscodeMessage = ''
    }
  },
};
</script>
<template>
  <div class="flex flex-col font-h text-left">
    <div class="font-semibold text-2xl mb-8 font flex justify-between items-center">
      <h2>Record instantly</h2>
    </div>
    <div class="flex flex-col gap-5">
      <div>
        <input type="text" @input="isValidUrl" :class="validMeetingUrl? 'border-green':'border-primary-400'" class="w-full text-sm font-ui px-2 h-10 rounded border  focus:border-primary-600" placeholder="Paste meeting link here" v-model="inputMeetingUrl">
        <div v-show="invalidMeetingUrl" class="flex mt-1">
          <img :src="require('@/assets/icons/caution.svg')" alt="" class="pr-2">
          <p class="text-sm text-primary-500 font-ui">{{invalidMeetingUrlMessage}}</p>
        </div>
        <div v-show="validMeetingUrl" class="flex mt-1">
          <img :src="require('@/assets/icons/green-check-small.svg')" alt="" class="pr-2">
          <p class="text-sm text-primary-500 font-ui">{{validMeetingUrlMessage}}</p>
        </div>
      </div>
      <div v-show="showPasscodeInput">
        <input type="text" @input="removeInvalidPasscode" class="w-full text-sm font-ui px-2 h-10 rounded border border-primary-400 focus:border-primary-600" placeholder="Enter meeting passcode here (if required)" v-model="inputPasscode">
        <div v-show="invalidPasscode" class="flex mt-1">
          <img :src="require('@/assets/icons/caution.svg')" alt="" class="pr-2">
          <p class="text-sm text-primary-500 font-ui">{{invalidPasscodeMessage}}</p>
        </div>
      </div>
      <div class="">
        <button @click="startRecording" class="w-full border bg-black font-ui text-md uppercase font-semibold rounded-lg flex h-10 flex justify-center items-center hover:bg-primary-900 ">
          <img :src="require('@/assets/icons/record.svg')" alt="" class="pr-2">
          <h1 class="text-xs text-white">Start Recording</h1>
          <img :src="require('@/assets/icons/arrow-white.svg')" alt="" class="pl-2">
        </button>
      </div>
    </div>
  </div>
</template>