<script lang="js">
import { initializeFirebase } from '@/firebase';
import firebase from 'firebase/compat/app';
import { signInWithCustomToken, setPersistence } from "firebase/auth"
const { auth } = initializeFirebase();
import axios from 'axios'
import { v1 as uuidv1 } from 'uuid'
import { create_custom_auth_token } from '../constants'
export default {
  name: 'FirebaseAuth',
  data() {
    return {
      loadingState: ''
    }
  },
  methods: {
    signInAttemptSuccessful(authResult) {
      console.log('checkhere signInAttemptSuccessful', authResult)
      if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        return false
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let data = {
        'userId': authResult.user.uid
      }
      let headers = {
        'Content-Type': 'application/json'
      }
      this.$store.commit('auth/setUFL', true)
      const env = process.env.VUE_APP_ENV
      axios.post(create_custom_auth_token, data, headers).then((response) => {
        console.log('checkhere', response)
        signInWithCustomToken(auth, response.data.token)
          .then((userCredential) => {
            userCredential.user.getIdToken().then((idToken) => {
              const cookie = document.cookie.match('(^|;) ?' + 'csrfToken' + '=([^;]*)(;|$)')
              const csrfToken = cookie ? cookie[2] : null
              const url = `${location.origin}/${env === 'prod' ? 'prod/auth' : 'dev/auth'}`
              axios({
                url: url,
                method: 'POST',
                data: {
                  idToken: idToken,
                  csrfToken: csrfToken
                }

              }).then(value => {
                this.$store.commit('auth/setUFL', false)
                if (value.data.status === 1) {
                  return false
                }
              }).catch(reason => {
                console.error(reason)
                return false
              })
            })
          })
      }).catch(
        err => {
          console.log('asdsadasdasd axios createCustomToken', err)
          return false
        }
      )
    },
    getStringForLoginState(state) {
      switch (state) {
        case 'status_check':
          return 'Please wait...'
        case 'logging_in':
          return 'Logging you in...'
      }
    }
  },
  async mounted() {
    let firebaseui = require('firebaseui');
    require('@/assets/css/firebaseui.css');
    let ui = new firebaseui.auth.AuthUI(auth);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    let uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: vm.signInAttemptSuccessful
      },
      signInFlow: 'popup',
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: 'select_account'
          }
        },
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true
        }
      ]
    };
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      ui.start('#firebaseui-auth-container', uiConfig);
      return
    }
    this.loadingState = 'status_check'
    setPersistence(auth, firebase.auth.Auth.Persistence.SESSION)
    const url = `${location.origin}/${process.env.VUE_APP_ENV === 'prod' ? 'prod/auth/status' : 'dev/auth/status'}`
    axios.get(url, { 'Access-Control-Allow-Origin': '*' }).then((res) => {
      console.log('asdsadasdasd /auth/status\'', res)
      if (res.data.status === 1) {
        this.loadingState = 'logging_in'
        let data = {
          'userId': res.data.data.uid
        }
        let headers = {
          'Content-Type': 'application/json'
        }
        axios.post(create_custom_auth_token, data, headers).then((response) => {
          signInWithCustomToken(auth, response.data.token)
            .then((userCredential) => {
              userCredential.user.getIdToken().then((idToken) => {
                if (idToken) {
                  // self.$router.push('success')
                }
                this.loadingState = 'login_success'
              })
            })
        })
        ui.start('#firebaseui-auth-container', '')
      } else {
        this.loadingState = ''
        ui.start('#firebaseui-auth-container', uiConfig)
      }
    })
    this.$cookies.set('csrfToken', uuidv1())
  },
};
</script>
<template>
  <div class="w-full relative h-3/4">
    <div
      v-show="loadingState === ''"
      class="text-4xl font-bold text-center text-black font-h mb-s48 pt-s8 pr-s32 pl-s16 leading-tighter"
    >
      <h2>Log in or</h2>
      <h2>Sign up</h2>
    </div>
    <div
      v-show="loadingState === ''"
      id="firebaseui-auth-container"
      class="max-w-[400px] m-auto"
    ></div>
    <div
      class="z-10 w-full h-full flex justify-center items-start absolute inset-0"
      v-show="loadingState === 'status_check' || loadingState === 'logging_in'"
    >
      <div
        class="z-10 w-full h-full flex justify-center items-center flex-col"
        v-show="
          loadingState === 'status_check' || loadingState === 'logging_in'
        "
      >
        <div
          class="text-4xl font-bold text-center text-black font-h mb-4 pt-16 pr-s32 pl-s16 leading-tighter"
        >
          Taking you to the place <br />
          you’re looking for...
        </div>
        <img
          src="../assets/auth_transition.png"
          alt="person holding a magnifying glass"
        />
      </div>
    </div>
  </div>
</template>