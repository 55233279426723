let cloudFN2 = ""

if (process.env.VUE_APP_ENV === 'prod') {
  cloudFN2 = 'https://us-central1-podcastpi-1b0c0.cloudfunctions.net'
} else {
  cloudFN2 = 'https://us-central1-dev-podcastpi.cloudfunctions.net'
}

export const create_custom_auth_token = cloudFN2 + '/createCustomToken'

export const landing_page_url = `https://${process.env.VUE_APP_ENV === 'prod' ? '' : 'dev.'}spext.co/connect-your-calendar`