export function googleAuthUrl() {
    //console.log("generateAuthUrl called in utils");

    const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const clientId = process.env.VUE_APP_GOOGLE_CLIENT;
    const redirectUri = process.env.VUE_APP_GOOGLE_REDIRECT_URI;

    const options = {
        redirect_uri: redirectUri,
        client_id: clientId,
        access_type: "offline",
        response_type: "code",
        prompt: "consent",
        scope: [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile',
            'openid',
            'https://www.googleapis.com/auth/calendar.readonly'
        ].join(" "),
    };
    const qs = new URLSearchParams(options);

    return `${baseUrl}?${qs.toString()}`;
}
