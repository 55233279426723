import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-1 h-screen overflow-hidden bg-primary-100" }
const _hoisted_2 = { class: "flex flex-col items-center nav" }
const _hoisted_3 = { class: "relative grid w-full grid-cols-10 gap-4 pl-3 ml-16" }
const _hoisted_4 = { class: "relative h-screen col-span-7 bg-white shadow-lg z-1 min-w-max" }
const _hoisted_5 = { class: "relative flex flex-col h-screen col-span-3 overflow-hidden px-1 bg-white border-primary-300 min-w-max" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNavigationBar = _resolveComponent("SideNavigationBar")!
  const _component_SetupAccount = _resolveComponent("SetupAccount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SideNavigationBar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("section", _hoisted_4, [
        _createVNode(_component_SetupAccount)
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("img", {
          src: require('@/assets/vector.png'),
          class: "h-screen",
          alt: "design vector"
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}