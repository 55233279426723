<template>
  <div ref="navbar">
    <nav class="flex flex-col items-center bg-white">
      <div class="flex flex-col items-center">
        <img
          class="cursor-pointer mt-5.5 inline-block"
          src="../assets/icons/spext-logo.svg"
        />
      </div>

      <!--			<IconButton-->
      <!--				classname="mt-5.5"-->
      <!--				:onClick="createNewDoc"-->
      <!--				v-tooltip.right="'Create New Doc'"-->
      <!--			>-->
      <!--				<FileAddIcon class="w-5 h-5"/>-->
      <!--			</IconButton>-->

      <!--			<IconButton-->
      <!--				classname="mt-4.5"-->
      <!--				@click="showQueueModal()"-->
      <!--				v-tooltip.right="'Notifications'"-->
      <!--				:variant="showNotifications ? 'active' : 'normal'"-->
      <!--			>-->
      <!--				<div class="relative">-->
      <!--					<BellIcon class="w-5 h-5"/>-->
      <!--					<div v-show="newPodcast" class="bg-red rounded-full h-2 top-0.25 right-0.25 w-2 absolute"/>-->
      <!--				</div>-->
      <!--			</IconButton>-->

      <VTooltip placement="right-start" :skidding="12" :distance="-5">
        <a
          :href="isDisabled ? 'javascript:void(0);' : `https://${isProd ? 'publish.' : 'public.'}spext.co/library`"
        >
          <IconButton classname="mt-4.5 group" :variant="isDisabled ? 'disabled' :'normal'">
            <Home classname="w-5 h-5 group-hover:text-red" />
          </IconButton>
        </a>
        <template #popper>
          <div class="flex items-center">
            <div class="w-2.5 h-2.5 -mr-2 rotate-45 bg-black"></div>
            <div
              class="p-2.5 rounded-md border border-primary-300 shadow-sm bg-primary"
            >
              <p class="text-white font-ui text-2xs">Your Library</p>
            </div>
          </div>
        </template>
      </VTooltip>

      <VTooltip placement="right-start" :skidding="12" :distance="-5">
        <a :href="isDisabled ? 'javascript:void(0);' :`https://app.${isProd ? '' : 'dev.'}spext.co`">
          <IconButton classname="mt-4.5 group" :variant="isDisabled ? 'disabled' : 'normal'">
            <Editor class="w-5 h-5 group-hover:text-red" />
          </IconButton>
        </a>
        <template #popper>
          <div class="flex items-center">
            <div class="w-2.5 h-2.5 -mr-2 rotate-45 bg-black"></div>
            <div
              class="p-2.5 rounded-md border border-primary-300 shadow-sm bg-primary"
            >
              <p class="text-white font-ui text-2xs">Editor Studio</p>
            </div>
          </div>
        </template>
      </VTooltip>

      <VTooltip placement="right-start" :skidding="12" :distance="-5">
        <IconButton classname="mt-4.5 group" :variant="isDisabled ? 'disabled' : 'active'">
          <Calendar classname="w-5 h-5 group-hover:text-red" />
        </IconButton>
        <template #popper>
          <div class="flex items-center">
            <div class="w-2.5 h-2.5 -mr-2 rotate-45 bg-black"></div>
            <div
              class="p-2.5 rounded-md border border-primary-300 shadow-sm bg-primary"
            >
              <p class="text-white font-ui text-2xs">Meetings Dashboard</p>
            </div>
          </div>
        </template>
      </VTooltip>

      <VTooltip placement="right-start" :skidding="12" :distance="-5">
        <a :href="isDisabled ? 'javascript:void(0);' :`https://app.${isProd ? '' : 'dev.'}spext.co/brandkit`">
          <IconButton classname="mt-4.5 group" :variant="isDisabled ? 'disabled' : 'normal'">
            <Brandkit class="w-5 h-5 group-hover:text-red" />
          </IconButton>
        </a>

        <template #popper>
          <div class="flex items-center">
            <div class="w-2.5 h-2.5 -mr-2 rotate-45 bg-black"></div>
            <div
              class="p-2.5 rounded-md border border-primary-300 shadow-sm bg-primary"
            >
              <p class="text-white font-ui text-2xs">Brand Kit</p>
            </div>
          </div>
        </template>
      </VTooltip>

      <VDropdown
        :trigger="['click']"
        :distance="8"
        :skidding="0"
        :arrow-padding="50"
        offset="4"
        placement="right-end"
        popoverClass="popover-sm"
      >
        <IconButton classname="mt-4.5 group" :variant="isDisabled ? 'disabled' : 'normal'">
          <More class="w-5 h-5 group-hover:text-red" />
        </IconButton>

        <template #popper>
          <div class="px-3 py-2 z-99">
            <div
              class="pr-4 text-sm cursor-pointer text-primary"
              @click="goToFaqs"
            >
              FAQs
            </div>
          </div>
        </template>
      </VDropdown>

      <!--			<VDropdown-->
      <!--          :trigger="['click']"-->
      <!--          :distance="8"-->
      <!--          :skidding="19"-->
      <!--          :arrow-padding="50"-->
      <!--				placement="right-start"-->
      <!--			>-->
      <!--				<IconButton-->
      <!--					classname="mt-4.5"-->
      <!--				>-->
      <!--					<HelpIcon class="w-5 h-5"/>-->
      <!--				</IconButton>-->

      <!--				<template #popper>-->
      <!--					<div class="px-3 py-2">-->
      <!--						<div-->
      <!--							v-for="(option, i) in helpDropdownOptions"-->
      <!--							:key="i"-->
      <!--							class="pr-4 text-sm cursor-pointer text-primary"-->
      <!--							:class="[(i + 1 === helpDropdownOptions.length) ? '' : ' pb-2 mb-2 border-b']"-->
      <!--							v-close-popover-->
      <!--							@click="selectedHelpOption(option.choice)"-->
      <!--						>-->
      <!--							{{ option.title }}-->
      <!--						</div>-->
      <!--					</div>-->
      <!--				</template>-->
      <!--			</VDropdown>-->

      <div class="flex-grow" />

      <VDropdown
        v-if="isAuthenticated"
        :trigger="['click']"
        :distance="8"
        :skidding="-11"
        :arrow-padding="50"
        offset="4"
        placement="right-end"
        popoverClass="popover-sm"
      >
        <div
          class="flex items-center justify-center w-8 h-8 mb-4 border-2 rounded-full cursor-pointer border-red"
        >
          <img
            class="rounded-full"
            :size="28"
            :src="photoURL"
            :username="displayName"
          />
        </div>

        <template #popper>
          <div class="px-3 py-2 z-99">
            <div
              v-for="(option, i) in profileDropdownOptions"
              :key="i"
              class="pr-4 text-sm cursor-pointer text-primary"
              :class="[
                i + 1 === profileDropdownOptions.length
                  ? ''
                  : 'pb-2 mb-2 border-b',
              ]"
              @click="selectedProfileOption(option)"
            >
              {{ option.title }}
            </div>
          </div>
        </template>
      </VDropdown>
      <LoginButton v-else v-show="showLogin"  :is-disabled="isDisabled"/>

      <!--      <Popover-->
      <!--      offset="6"-->
      <!--      placement="right-end"-->
      <!--      popoverClass="popover-sm"-->
      <!--      popoverArrowClass="hidden"-->
      <!--      @show="openPopup='profile';"-->
      <!--      @hide="openPopup='';"-->
      <!--    >-->
      <!--      <div-->
      <!--        class="flex items-center justify-center w-8 h-8 mb-4 border-2 rounded-full cursor-pointer border-red"-->
      <!--        v-tooltip.right="openPopup === 'profile' ? '' : 'Profile'"-->
      <!--      >-->
      <!--        <Avatar :size="28" :src="photoURL" :username="displayName"/>-->
      <!--      </div>-->

      <!--      <template slot="popover">-->
      <!--        <div class="px-3 py-2">-->
      <!--          <div-->
      <!--            v-for="(option, i) in profileDropdownOptions"-->
      <!--            :key="i"-->
      <!--            class="pr-4 text-sm cursor-pointer text-primary"-->
      <!--            :class="[(i + 1 === profileDropdownOptions.length) ? '' : 'pb-2 mb-2 border-b']"-->
      <!--            v-close-popover-->
      <!--            @click="selectedProfileOption(option)"-->
      <!--          >-->
      <!--            {{option.title}}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </template>-->
      <!--    </Popover>-->
    </nav>
  </div>
</template>

<script>
import IconButton from "@/components/Button/IconButton";
import LoginButton from "@/components/common/LoginButton.vue"
import { initializeFirebase } from "@/firebase";
import { signOut } from "@firebase/auth";
import axios from "axios";
import { landing_page_url } from "@/constants";
import consoleLog from "@/utils/console-log";
import Home from "./icons/Home.vue";
import Calendar from "./icons/Calendar.vue";
import Editor from "./icons/Editor.vue";
import Brandkit from "./icons/Brandkit.vue";
import More from "./icons/More.vue";
import { ref, onValue } from "firebase/database";
import { mapGetters } from "vuex";
const { auth, rtdb } = initializeFirebase();
export default {
  props: {
    clipper: {
      type: Boolean,
      default: false,
    },
    working: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      uniqueUidColor: "red",
      openPopup: true,
      showMidMenu: false,
      searchString: "",
      user: true,
      switching: true,
      newPodcast: true,
      panelToShow: true,
      showNotifications: true,
      openModal: true,
      logout: false,

      photoURL: "",
      displayName: "",
      profileDropdownOptions: [
        {
          title: "Logout",
        },
      ],
      channelId: null,
    };
  },
  components: {
    IconButton,
    // FileAddIcon,
    // BellIcon,
    Home,
    Calendar,
    Editor,
    Brandkit,
    More,
    LoginButton
  },
  mounted() {
    this.photoURL = this.$store.state.auth.picture;
    this.displayName = this.$store.state.auth.name;
    this.setChannelId();
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
       unifiedAuthLoading: "auth/unifiedAuthLoading",
    }),
    helpDropdownOptions() {
      if (this.editMode === "editTranscript") {
        return [
          {
            title: "FAQs",
            choice: "faq",
          },
          // {
          //   title: 'Contact Us',
          //   choice: 'contactUs'
          // },
          ...(this.clipper
            ? []
            : [
                {
                  title: "Walkthrough",
                  choice: "aboutTranscript",
                },
              ]),
          {
            title: "Tutorial Videos",
            choice: "tutorialVideos",
          },
        ];
      }
      return [
        {
          title: "FAQs",
          choice: "faq",
        },

        {
          title: "Tutorial Videos",
          choice: "tutorialVideos",
        },
      ];
    },
    isProd() {
      return process.env.VUE_APP_ENV === "prod";
    },
    isDisabled() {
      return !this.isAuthenticated || this.unifiedAuthLoading;
    },
    showLogin() {
      return this.$router.currentRoute.value.name !== 'auth'
    }
  },
  methods: {
    setChannelId() {
      onValue(
        ref(rtdb, `/${this.$store.state.auth.uid}/userInfo/channel/id`),
        (snap) => {
          const cid = snap.val();
          if (cid) this.channelId = cid;
        },
        {
          onlyOnce: true,
        }
      );
    },
    goToSpextDrive() {
      const spextDomain = process.env.VUE_APP_SPEXT_DOMAIN;
      const driveUrl = `${spextDomain}/drive`;
      location.href = driveUrl;
    },
    goToFaqs() {
      const FaqUrl = "http://help.spext.co/faqs";
      window.open(FaqUrl, "_blank");
    },
    async signOut() {
      if (
        location.hostname === "localhost" ||
        location.hostname === "127.0.0.1"
      ) {
        await signOut(auth);
        window.location = window.location.origin;
        return;
      }
      const url = `${location.origin}/${
        process.env.VUE_APP_ENV === "prod"
          ? "prod/auth/logout"
          : "dev/auth/logout"
      }`;
      try {
        const res = await axios.get(url);
        if (res.data.status !== 1) {
          console.error("Unified logout failed", res.data.message);
        }
        await signOut(auth);
        window.location = landing_page_url;
      } catch (err) {
        consoleLog(`error logging out ${err}`);
      }
    },
    // ...mapActions({
    //     openModal: 'dialogs/openModal',
    //     logout: 'app/logout'
    // }),
    // toggleEditMode: function () {
    //     window.analytics.track('sidebar_editor_modeToggleClicked')
    //
    //     if (this.editMode === 'editMedia') this.setTitle('editTranscript')
    //     else this.setTitle('editMedia')
    // },
    emitClick: function () {
      this.onClick();
      this.$emit("click");
    },
    routeToSpext() {
      location.href = location.origin;
    },
    selectedHelpOption(option) {
      switch (option) {
        case "faq":
          // window.analytics.track('sidebar_faqClicked')
          window.open("http://help.spext.co/faqs", "_blank");
          break;
        case "tutorialVideos":
          // window.analytics.track('sidebar_tutorialClicked')
          window.open(
            "http://help.spext.co/en/collections/1872349-step-by-step-video-tutorials",
            "_blank"
          );
          break;
        case "contactUs":
          window.Intercom("showNewMessage");
          break;
        default:
          this.$emit("helpSelected", option);
      }
    },
    selectedProfileOption: async function (option) {
      if (option.title === "Profile") {
        this.openModal({ name: "AccountInfo" }); // chnage
      } else if (option.title === "Logout") {
        await this.signOut();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-popper__arrow-outer {
  border-color: black;
  background: black;
}

.loadingCircle {
  animation: rotate 0.6s linear infinite;

  .circleStroke {
    stroke-width: 2px;
    stroke: #e2462c;
    stroke-dasharray: 254;
    stroke-dashoffset: 1000;
  }
}

nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 60px;
  min-width: 60px;
  background-color: #fff;
  box-shadow: inset -1px 0px 0px #e5e7eb;
}

.avatar-with-border {
  border-radius: 50%;
  height: 38px;
  width: 38px;
  border-width: 2px;
  border-color: #d2d8e3;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.userNotActive {
  filter: grayscale(100%);
  cursor: default !important;
  pointer-events: none;
}

[type="checkbox"]:disabled + label {
  cursor: pointer;
}

[type="checkbox"]:disabled + label:before {
  border: 2px solid #000228;
}

[type="checkbox"]:disabled + label:after {
  color: #000228;
}
</style>
