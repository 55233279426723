import { ref, onValue } from "firebase/database";
import { Module } from "vuex";
import { initializeFirebase } from "@/firebase";
const { rtdb } = initializeFirebase();

export const auth: Module<any, any> = {
  namespaced: true,
  state: {
    uid: "",
    email: "",
    name: "",
    picture: "",
    isAuthenticated: false,
    unifiedAuthLoading: false,
    isUserAllowed: true,
  },

  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    unifiedAuthloading: (state) => state.unifiedAuthLoading,
    allowedLoginsArray: (state) => state.allowedLoginsArray,
    isUserAllowed: (state) => state.isUserAllowed,
  },
  mutations: {
    updateUser(state, user) {
      onValue(ref(rtdb, `configs/allowed_logins`), (snap) => {
        const allowedLoginsObject = snap.val() || {};
        const allowedLoginsArray = Object.keys(allowedLoginsObject);
        if (user) {
          if (allowedLoginsArray.includes(user.uid)) {
            console.log("setting isUserAllowed to true");
            state.isUserAllowed = true;
          } else {
            console.log("setting isUserAllowed to false");
            state.isUserAllowed = false;
          }
          state.uid = user.uid;
          state.email = user.email;
          state.name = user.displayName;
          state.picture = user.photoURL;
          state.isAuthenticated = true;
        } else {
          state.isUserAllowed = true;
          state.isAuthenticated = false;
        }
      });
    },
    setUFL(state, payload) {
      state.unifiedAuthLoading = payload;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("updateUser", user);
    },
  },
};
