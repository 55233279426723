import Api from '@/services/api/Api';

export default {
    addAccount( body: any) {
        return Api().post(`account`, body);
    },

    getAccounts(uid: string) {
        return Api().get(`${uid}/accounts`);
    },

    deleteAccount(uid: string, aid: string) {
        return Api().delete(`${uid}/account?aid=${aid}`);
    }
};