import { Module } from 'vuex';
import SettingsApi from '@/services/api/Settings'
import consoleLog from '@/utils/console-log'
export const userSettings:Module<any, any> = {
  namespaced: true,
  state: {
    settings: {
        record: 'mine',
        share: 'me'
    },
  },
  getters: {},
  mutations: {
    updateSettings(state, settings) {
      state.settings = settings;
    }
  },
  actions: {
    async getSettings({ commit, rootState }) {
      const uid = rootState.auth.uid;
      try {
        const response = await SettingsApi.getSettings(uid);
        if(response.data.status === 'success') {
          commit('updateSettings', response.data.data.settings);
        }
      }catch (error) {
        consoleLog(`Error getting settings: ${error}`);
      }
    },
    setSettings({commit}, settings) {
      commit('updateSettings', settings);
    },
  },
};