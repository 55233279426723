<script lang="js">
import AccountApi from '@/services/api/Account'
import SettingsApi from '@/services/api/Settings'
import {googleAuthUrl} from "@/utils/generate-auth-url";
import consoleLog from "@/utils/console-log";
import ButtonDesign from "@/components/Button/ButtonDesign.vue";
import StepDesign from "@/components/StepDesign.vue";
import RadioInput from "@/components/input/RadioInput.vue";
  export default {
    components: {
      ButtonDesign,
      StepDesign,
      RadioInput,
    },
    data() {
      return {
        slotOpen: 1,
        googleHover: false,
        microsoftHover: false,
        calendlyHover: false,
        record: '',
        share: '',
        loginError: false,
        loginSuccess: false,
        successModal: false,
        addAccountLoading: false,
        updateSettingsLoading: false,
      }
    },
    methods: {
      changeSlot(id) {
        if(!this.loginError && this.loginSuccess) {
          if(this.slotOpen == 1 )
          {
            id == 2 ? this.slotOpen = id : ''
          } else{
            this.slotOpen = id
          }
        }
      },
      openGoogleAuthUrl(){
        window.location.assign(googleAuthUrl());
      },
      removeCodeFromUrl() {
        this.$router.push({path: this.$route.path, query: {}})
      },
      async addAccount(code){
        this.addAccountLoading = true
        const uid = this.$store.state.auth.uid
        const email = this.$store.state.auth.email
        const name = this.$store.state.auth.name
        try{
          const body = {
            uid: uid,
            code: code,
            user_name: name,
            user_email: email,
            calendar_type: 'google'
          }
          const response = await AccountApi.addAccount( body )
          if(response.data.status == 'success'){
            await this.updateDefaultSettings()
            this.loginSuccess = true
            this.successModal = true
            this.loginError = false
            setTimeout(() => { this.successModal = false }, 4000)
            this.changeSlot(2)
            this.addAccountLoading = false
          }else{
            this.loginError = true
            this.loginSuccess = false
            this.addAccountLoading = false
          }
        }catch (error) {
          this.loginError = true
          this.loginSuccess = false
          this.addAccountLoading = false
          consoleLog(`Add Account Error: ${error}`)
        }
      },
      async updateDefaultSettings(){
        const uid = this.$store.state.auth.uid
        try{
          const body = {
            settings:{
              record: this.record,
              share: this.share,
            }
          }
          const response = await SettingsApi.updateSettings(uid, body)
          if(response.data.status == 'success'){
            this.$store.dispatch('userSettings/setSettings', body.settings)
          }
        }catch (error) {
          consoleLog(`Update Settings Error: ${error}`)
        }
      },
      async updateSettings(){
        this.updateSettingsLoading = true
        const uid = this.$store.state.auth.uid
        try{
          const body = {
            settings:{
              record: this.record,
              share: this.share,
            }
          }
          const response = await SettingsApi.updateSettings(uid, body)
          if(response.data.status == 'success'){
            this.$store.dispatch('userSettings/setSettings', body.settings)
            this.updateSettingsLoading = false
            this.$router.push({name: 'dashboard'})
          }
        }catch (error) {
          consoleLog(`Update Settings Error: ${error}`)
          this.updateSettingsLoading = false
        }
      },
    },
    computed:{
      isGoogleAuthCodePresent() {
        return this.$route.query.code;
      },
      isGoogleAuthErrorPresent() {
        return this.$route.query.error;
      },
    },
    mounted() {
      this.record = this.$store.state.userSettings.settings.record;
      this.share = this.$store.state.userSettings.settings.share;

      if(this.isGoogleAuthCodePresent) {
        this.addAccount(this.isGoogleAuthCodePresent)
      } else if(this.isGoogleAuthErrorPresent) {
        this.loginError = true
      }
      this.removeCodeFromUrl()
    },
  };
</script>

<template>
  <div>
    <div class="bg-white shadow-md h-screen w-full p-10  text-primary">
      <div class="flex flex-col gap-y-6">

        <div class="flex gap-2 items-center">
          <img :src="require('@/assets/icons/calendar.svg')" alt="">
          <div class="font-h text-4xl font-semibold">
            <h1>Connect your calendar</h1>
          </div>
        </div>

        <teleport to="body">
          <div class="inset-0 z-100 fixed overflow-y-auto drop-shadow-2xl bg-primary-dark/60" v-if="successModal">
            <div class="flex justify-center items-center h-screen">
              <div class="flex flex-col justify-between bg-white h-44 max-w-s800 min-w-max p-10">
                <div class="flex flex-row">
                  <div class="m-4 mr-5">
                    <img :src="require('@/assets/icons/google-calendar-logo-large.svg')" alt="">
                  </div>
                  <div class="m-3">
                    <div class="flex gap-1">
                      <h1 class="text-3xl text-primary font-semibold font-h">Calendar added successfully</h1>
                      <img :src="require('@/assets/icons/green-check.svg')" alt="">
                    </div>
                    <div>
                      <p class="text-primary-600 font-medium">Spext can now help you record and edit all your meetings directly!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </teleport>

        <div class="flex flex-col">
          <StepDesign
              :isEnabled="true"
              :open="slotOpen == 1"
              :stepHeadClass="'text-left capitalize'"
              :stepBodyClass="'text-left'"
              :showConnector="true"
              @onClick="changeSlot(1)"
          >
            <div @click="changeSlot(1)" class="flex items-center">
              <h2 class="normal-case">1. Which calendar should we integrate with?</h2>
            </div>
            <template v-slot:stepBody>
              <div class="flex flex-col lg:flex-row gap-2">

                <button
                    @mouseover="googleHover = true"
                    @mouseleave="googleHover = false"
                    @click="openGoogleAuthUrl"
                    class="h-14 px-5 py-4 bg-primary-100 w-[200px] border border-primary-200 rounded-lg flex gap-2 font-ui text-xs text-black items-center">
                  <img :src="require('@/assets/icons/google-calendar-logo.svg')" alt="google calendar logo">
                  <div class="flex px-0 flex-col justify-around text-left">
										<span>
                      <h3>Google Calendar</h3>
										</span>
                    <span v-if="loginError" class="text-2xs">
                      <p v-show="!googleHover" class="text-red">Permission Denied</p>
                      <p v-show="googleHover" class="text-primary-600 underline">Try again</p>
										</span>
                  </div>
                  <img v-if="addAccountLoading" src="@/assets/icons/loader.png" class="w-5 h-5 rounded-md overflow-hidden animate-spin-slow loader">
                  <img v-if="loginError" v-show="!googleHover" :src="require('@/assets/icons/exclaim-circle.svg')" alt="exclaim circle">
                  <img v-if="loginError" v-show="googleHover" :src="require('@/assets/icons/refresh-icon.svg')" alt="refresh icon">
                </button>

                <button @mouseover="microsoftHover = true" @mouseleave="microsoftHover = false" class="h-14 px-5 opacity-50 py-4 bg-primary-100 w-48 border border-primary-200 rounded-lg flex gap-2 font-ui text-xs text-black items-center">
                  <img :src="require('@/assets/icons/outlook-logo.svg')" alt="microsoft outlook logo">
                  <div class="flex px-0 flex-col justify-around text-left">
										<span>
                      <h3>Microsoft Outlook</h3>
										</span>
                    <span class="text-2xs">
                      <p v-show="microsoftHover" class="text-primary-600">Coming Soon!</p>
										</span>
                  </div>
                </button>
                <button @mouseover="calendlyHover = true" @mouseleave="calendlyHover = false" class="h-14 px-5 opacity-50 py-4 bg-primary-100 w-48 border border-primary-200 rounded-lg flex gap-2 font-ui text-xs text-black items-center">
                  <img :src="require('@/assets/icons/calendly-logo.svg')" alt="">
                  <div class="flex px-0 flex-col justify-around text-left">
										<span>
                      <h3>Calendly</h3>
										</span>
                    <span class="text-2xs">
                      <p v-show="calendlyHover" class="text-primary-600">Coming Soon!</p>
										</span>
                  </div>
                </button>

              </div>
            </template>
          </StepDesign>

          <StepDesign
              :isEnabled="true"
              :open="slotOpen == 2"
              :stepHeadClass="'text-left capitalize'"
              :stepBodyClass="'text-left'"
              :showConnector="false"
              @onClick="changeSlot(2)"
          >
            <div class="flex justify-between items-start">
              <div class="w-full flex items-center" @click="changeSlot(2)">
                <h2 class="normal-case">2. Which meetings should Spext join?</h2>
                <img v-if="updateSettingsLoading" src="@/assets/icons/loader.png" class="w-5 h-5 ml-2 rounded-md overflow-hidden animate-spin-slow loader">
              </div>
              <div v-show="slotOpen == 2">
                <ButtonDesign @click="updateSettings">DONE</ButtonDesign>
              </div>
            </div>
            <template v-slot:stepBody>
              <div class="flex flex-col gap-y-5 font-ui text-xs">
                <div @click="record = 'mine'" >
                  <RadioInput
                      :name="'meetings'"
                      :checked="record == 'mine'"
                      :label="'Join only meetings I own'">
                  </RadioInput>
                  <h2 class="ui text-primary-600 ml-6 mt-2 pl-0.5 ">You can still invite Spext to other meetings, but <span class="font-semibold text-primary-700">only after they are live.</span></h2>
                </div>
                <div @click="record = 'none'" >
                  <RadioInput
                      :name="'meetings'"
                      :checked="record == 'none'"
                      :label="'Don’t join any meetings automatically'">
                  </RadioInput>
                  <h2 class="ui text-primary-600 ml-6 mt-2 pl-0.5 ">You can customise which meetings need to be recorded once the calendar is integrated.</h2>
                </div>
              </div>
            </template>
          </StepDesign>

<!--          <StepDesign-->
<!--              :isEnabled="true"-->
<!--              :open="slotOpen == 3"-->
<!--              :stepHeadClass="'text-left capitalize'"-->
<!--              :stepBodyClass="'text-left'"-->
<!--              :showConnector="false"-->
<!--              @onClick="changeSlot(3)"-->
<!--          >-->
<!--            <div  class="flex justify-between items-start">-->
<!--              <div class="w-full flex items-center" @click="changeSlot(3)">-->
<!--                <h2>3. Who should get the recording?</h2>-->
<!--                <img v-if="updateSettingsLoading" src="@/assets/icons/loader.png" class="w-5 h-5 ml-2 rounded-md overflow-hidden animate-spin-slow loader">-->
<!--              </div>-->
<!--              <div v-show="slotOpen == 3">-->
<!--                <ButtonDesign @click="updateSettings" >DONE</ButtonDesign>-->
<!--              </div>-->
<!--            </div>-->
<!--            <template v-slot:stepBody>-->
<!--              <div class="flex flex-col gap-y-4 font-ui text-xs">-->
<!--                <div @click="share = 'me'" >-->
<!--                  <RadioInput-->
<!--                      :name="'meetings'"-->
<!--                      :checked="share == 'me'"-->
<!--                      :label="'Only Me'">-->
<!--                  </RadioInput>-->
<!--                </div>-->
<!--                <div @click="share = 'all'" >-->
<!--                  <RadioInput-->
<!--                      :name="'meetings'"-->
<!--                      :checked="share == 'all'"-->
<!--                      :label="'All Participants in the invite'">-->
<!--                  </RadioInput>-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </StepDesign>-->

        </div>

      </div>
    </div>
  </div>
</template>