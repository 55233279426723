<script lang="js">
import consoleLog from '@/utils/console-log';
import AccountApi from '@/services/api/Account'
import {createToaster} from "@meforma/vue-toaster";

export default {
	name: "AccountsList",
  data() {
    return {
      accounts: [],
      accountKey: '',
      deleteAccountLoader: false
    }
  },
  created() {
    this.fetchAccounts()
  },
  methods: {
		addNewCalendar() {
			this.$router.push({name: 'setup'})
		},
    async fetchAccounts(){
      const uid = this.$store.state.auth.uid
      try{
        const response = await AccountApi.getAccounts(uid)
        this.accounts = response.data.data.accounts
      } catch (error) {
        consoleLog(`Fetch Account Error: ${error}`)
      }
    },
    async deleteAccount(account){
      const uid = this.$store.state.auth.uid
      const aid = account.sk.replace('a#', '')
      this.accountKey = account.sk
      const toaster = createToaster({
        position: "bottom",
        });
      this.deleteAccountLoader = true
      try{
        const response = await AccountApi.deleteAccount(uid, aid)
        if(response.data.status === 'success'){
          await this.fetchAccounts()
          toaster.show(`<span class="emoji">💡</span>“${account.email}” calendar has been disconnected`);
          if (this.accounts.length === 0) {
            this.$router.push({name: 'setup'})
          }
          this.deleteAccountLoader = false
        }
      }catch (error) {
        consoleLog(`Delete Account Error: ${error}`)
        this.deleteAccountLoader = false
      }
    },
	},
};
</script>
<template>
  <div class="flex flex-col font-h text-left">
				<div class="font-semibold text-2xl mb-8 font flex justify-between items-center">
					<h2>Connected calendars</h2>
					<div class="cursor-pointer fill-current bg:white text-primary hover:bg-primary-100" @click="addNewCalendar">
            <img :src="require('@/assets/icons/plus-icon.svg')" alt="">
					</div>
				</div>
        <div v-for="account in accounts" :key="account.sk">
          <div class="flex mb-3 flex-col gap-y-4 text-xs font-h">
            <div class="px-5 py-4 bg-primary-100 w-full border border-primary-200 rounded-lg flex justify-between items-center font-ui text-xs">
              <div class="flex gap-2 items-center">
                <img :src="require('@/assets/icons/google-calendar-logo.svg')" alt="">
                <p class="text-sm">{{account.email}}</p>
              </div>
              <div v-if="!deleteAccountLoader" class="cursor-pointer" @click="deleteAccount(account)">
                <VTooltip
                  placement="top"
                  :skidding="0"
                  :distance="5"
                >
                  <img  :src="require('@/assets/icons/cancel-icon.svg')" alt="">
                  <template #popper>
                    <div class="flex flex-col items-center">
                      <div class="p-2 rounded-md border border-primary-300 shadow-sm bg-primary">
                      <p class="text-white text-xs">Disconnect</p>
                      </div>
                      <div class="w-2.5 h-2.5 -mt-2 rotate-45 bg-black"></div>
                    </div>
                  </template>
                </VTooltip>
              </div>
              <div v-if="deleteAccountLoader && accountKey == account.sk">
                <img src="@/assets/icons/loader.png" class="w-5 h-5 ml-2 rounded-md overflow-hidden animate-spin-slow loader">
              </div>
            </div>
          </div>
        </div>
  </div>
</template>
<style>
.c-toast-container{
  padding: 6em;
}
.v--default-css .c-toast{
  display: flex;
  background-color: #000228;
  padding: 0.3em 1em;
  min-height: 2.5em;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  filter: drop-shadow(0 0 0.10rem gray);
}
.emoji{
  font-size: 1.4em;
  margin-right: 0.2em;
}
</style>