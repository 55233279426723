<script>
import { ref, onValue } from "firebase/database";
import { initializeFirebase } from "@/firebase";
import { onAuthStateChanged } from "@firebase/auth";
const { auth } = initializeFirebase();
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      allowedLoginsArray: [],
    };
  },
  computed: {
    ...mapGetters({
      isUserAllowed: "auth/isUserAllowed",
    }),
  },
  mounted: function () {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.dispatch("auth/setUser", user);
        if (this.$router.currentRoute.value.name === "auth") {
          this.$router.replace({ name: "dashboard" });
        }
      } else {
        this.$store.dispatch("auth/setUser", null);
      }
    });
  },
  watch: {
    isUserAllowed: {
      handler(val) {
        console.log("is allowed watcher", val);
        if (!val) {
          console.log("replace", val);
          this.$router.replace({ name: "shutdown-notice" });
        }
      },
      immediate: true,
    },
  },
};
</script>
<template>
  <router-view />
</template>

<style lang="scss">
@import "@/assets/css/main.css";
@import "floating-vue/dist/style.css";
</style>
